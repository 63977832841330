<template>
  <div class="index">
    <h1>Learn Thai</h1>
    <p>
      We believe our approach of teaching Thai is not only the most effective, but it's also enjoyable and useful from day one. Check out the different courses to get a preview of the hundreds of useful Thai phrases that can help make your time in Thailand more enjoyable.
    </p>
    <h2>Courses</h2>
    <div class="courses">
      <router-link
        :to="'/thai/course/' + course.id"
        class="card"
        v-for="course in courses"
        v-bind:key="course.id"
      >
        <h2>{{ course.english }}<br />{{ course.thai }}</h2>
        <p>{{ course.description }}</p>
      </router-link>
    </div>
    <h2>Translation Services</h2>
    <p>
      We also offer translation services if you have something you would like to translate to Thai. This could be everything from content for your website, a book, a Thai song, homework, or something else entirely.
    </p>
    <p>
      Check out our <router-link to="/pricing">pricing page</router-link> for more information.
    </p>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      courses: [
        { id: "food", english: "Food", thai: "อาหาร", description: "Order food at restaurants and street shops - request your favorite food and ask for the price" },
        { id: "directions", english: "Directions", thai: "เส้นทาง", description: "Ask and give directions when taking cab, motorbike, van, bus, BTS or walking somewhere" },
        // { id: "numbers", english: "Numbers", thai: "ตัวเลข", description: "Pronounce any number from 0 to one million, and learn how to pronounce Thai calendar years, phone numbers and prices" },
        // { id: "immigration", english: "Immigration", thai: "ตรวจคนเข้าเมือง", description: "Ask for help to find and fill out your visa extension form" },
        // { id: "romance", english: "Romance", thai: "โรแมนติก", description: "Make your partner happy by sharing romantic words in Thai" },
        // { id: "accommodation", english: "Accommodation", thai: "ที่พัก", description: "Find hotels and apartments, communicate about electricity and water bills" },
        // { id: "entertainment", english: "Entertainment", thai: "ความบันเทิง", description: "Ask for song recommendations and invite someone to go to the cinema" },
        // { id: "business", english: "Business", thai: "ธุรกิจ", description: "Impress your business partners, clients and co-workers" },
        // { id: "education", english: "Education", thai: "การศึกษา", description: "Ask your classmates about the homework assignments, get to know students and teachers" },
      ]
    }
  },
  mounted() {
    document.title = "Thai - PocketLang.com"
  },
}
</script>

<style scoped lang="scss">
.index {
  margin: 0 .5rem;
  .courses {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
    .card {
      background-color: white;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.1);
      text-decoration: none;
      color: inherit;
      h2 {
        margin: 0;
        padding: 0.5rem;
      }
      p {
        margin: 0;
        padding: 0 0.5rem 0.5rem 0.5rem;
        font-size: 0.8em;
      }
      .icon {
        padding: 1rem;
        svg {
          width: 100%;
        }
      }
    }
  }
}
</style>